import React from "react";
function RegisterButton() {
  return (
    <a
      className=" mt-4 justify-center items-center  px-11 py-2 rounded-[100px] lg:px-20 lg:py-2.5 text-white bg-gradient-to-br from-black to-black hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-md px-5 py-2.5 text-center "
      href="/register"
    >
      Register
    </a>
  );
}

export default RegisterButton;
