import React from "react";
import { motion } from "framer-motion";
import Pdf from "../assets/release-of-liability-activity.pdf";
import Pdf2 from "../assets/Fundraising Registration Form.jpg";

function Footer() {
  const leftVarient = {
    initial: {
      x: -500,
      opacity: 0,
    },
    final: {
      x: 0,
      opacity: 1,
    },
    transition: {
      delay: 3,
      type: "spring",
    },
  };
  const rightVarient = {
    initial: {
      x: 500,
      opacity: 0,
    },
    final: {
      x: 0,
      opacity: 1,
    },
    transition: {
      delay: 2,
      type: "spring",
    },
  };
  return (
    <div className="flex justify-center items-center mt-[10rem] bg-[#FE4531]  py-[6rem] overflow-hidden lg:mt-[8rem]">
      <article className="flex justify-between items-center flex-col-reverse gap-[8rem] lg:flex-row overflow-hidden lg:gap-[20rem]">
        <motion.div
          variants={leftVarient}
          initial="initial"
          animate="final"
          viewport={{ once: false, amount: 0.1 }}
          className="flex justify-center items-center flex-col gap-[1rem] lg:items-start"
        >
          <div className="flex justify-center items-center gap-3">
            <p className="text-[#fff] text-[20px] font-bold">
              Bikers For LeBonheur
            </p>
          </div>
          <p className="text-[#fff] text-[18px] w-[80vw] lg:w-[22vw] md:w-[50vw]"></p>

          <p className=" text-[#fff] text-[18px] w-[80vw] lg:w-[22vw] md:w-[50vw] ">
            ©Bikers For Le Bonheur 2023. All rights reserved
          </p>
        </motion.div>
        <motion.div
          variants={rightVarient}
          initial="initial"
          animate={"final"}
          className="flex justify-center items-center gap-[6rem] lg:gap-[4rem]"
        >
          <div className="flex flex-col gap-[0.8rem]">
            <h1 className="text-[#fff] text-[18px] font-bold lg:text-[20px]">
              Club
            </h1>
            <a
              href="/register"
              className="text-[#fff] text-[14px] lg:text-[18px]"
            >
              Join
            </a>
            <a href="login" className="text-[#fff] text-[14px] lg:text-[18px]">
              Login
            </a>
            <a
              href="/information"
              className="text-[#fff] text-[14px] lg:text-[18px]"
            >
              Event Information
            </a>
            <a
              href="/information"
              className="text-[#fff] text-[14px] lg:text-[18px]"
            >
              About us
            </a>
          </div>
          <div className="flex flex-col gap-[0.8rem]">
            <h1 className="text-[#fff] text-[18px] font-bold lg:text-[20px]">
              Help
            </h1>
            <a
              href="/contact"
              className="text-[#fff] text-[14px] lg:text-[18px]"
            >
              Contact Us
            </a>
            <a
              href={Pdf2}
              target="_blank"
              className="text-[#fff] text-[14px] lg:text-[18px]"
            >
              Fundraising Registation
            </a>
            <a
              href={Pdf}
              target="_blank"
              className="text-[#fff] text-[14px] lg:text-[18px]"
            >
              Release of Liability
            </a>
            <a
              href="fundraising"
              className="text-[#fff] text-[14px] lg:text-[18px]"
            >
              Sponsors
            </a>
          </div>
        </motion.div>
      </article>
    </div>
  );
}

export default Footer;
