import React, { useState } from "react";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

const RegisterComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const signUp = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        navigate("/tickets");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <section className="flex flex-col overflow-auto justify-center items-center px-5 xl:flex-row mt-[5rem] lg:items-end lg:justify-center lg:gap-[6rem]0">
      <div className="sign-in-container">
        {/* <div className="md:w-1/3 max-w-sm"> */}
        <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
          <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
            Register
          </p>
        </div>
        <form onSubmit={signUp}>
          <input
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mb-3"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mb-3"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <button
            class="w-full px-4 py-2 mt-5 font-medium text-center text-white bg-black transition-colors duration-200 rounded-md bg-black hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1"
            type="submit"
          >
            Register
          </button>
        </form>
        <div className="mt-4 font-semibold text-sm text-slate-500 text-center md:text-left">
          Already have an account?{" "}
          <a
            className="text-red-600 hover:underline hover:underline-offset-4"
            href="/login"
          >
            Login
          </a>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default RegisterComponent;
