import React from "react";
// import Hero from "../component/Hero";
import FundraisingComponent from "../component/Fundraising/FundraisingComponent";
import GoldFundraisingPricing from "../component/Fundraising/GoldFundraisingPricing";
import SilverFundraisingPricing from "../component/Fundraising/SilverFundraisingPricing";
import BronzeFundraisingPricing from "../component/Fundraising/BronzeFundraisingPricing";
import Footer from "../component/Footer";
const Fundraising = () => {
  return (
    <div>
      {/* <FundraisingComponent /> */}
      <GoldFundraisingPricing />
      <SilverFundraisingPricing />
      <BronzeFundraisingPricing />
      {/* <Footer /> */}
    </div>
  );
};

export default Fundraising;
