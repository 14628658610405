import React from "react";

import Footer from "../component/Footer";
import InformationComponent from "../component/Information/InfomrationComponent";

const Information = () => {
  return (
    <div>
      <InformationComponent />
      <Footer />
    </div>
  );
};

export default Information;
