import React from "react";

import HomeDashboard from "../component/Dashboard/HomeDashboard";
import Footer from "../component/Footer";

const Dashboard = () => {
  return (
    <div>
      <HomeDashboard />
      <Footer />
    </div>
  );
};

export default Dashboard;
