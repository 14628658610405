import React from "react";

import RegisterComponent from "../component/Register/RegisterComponent";
import Footer from "../component/Footer";

const Register = () => {
  return (
    <div>
      <RegisterComponent />
      <Footer />
    </div>
  );
};

export default Register;
