import React from "react";

import Hero from "../component/Hero";

import Testimonies from "../component/Testimonies";
import Footer from "../component/Footer";

const Home = () => {
  return (
    <div>
      <Hero />
      <Testimonies />
      <Footer />
    </div>
  );
};

export default Home;
