import React from "react";
// import { CgSearch } from "react-icons/cg";
import Lottie from "lottie-react";
import headerLottie from "../assets/lottie/bike-final.json";
import { motion } from "framer-motion";

function Hero() {
  const BikeVarient = {
    initial: {
      x: 200,
      opacity: 0,
    },
    final: {
      x: 0,
      opacity: 1,
    },
    transition: { bounce: 0.4, delay: 2, staggerChildren: 0.5 },
  };
  const TextVarient = {
    initial: {
      x: -1000,
    },
    final: {
      x: 0,
    },
    transition: { type: "spring", bounce: 0.4, duration: 1 },
  };
  const AngelVarient = {
    initial: {
      y: 100,
      opacity: 0,
    },
    final: {
      y: 0,
      opacity: 1,
    },
    transition: { type: "spring", bounce: 0.9, duration: 2 },
  };

  return (
    <div className="flex flex-col overflow-auto justify-center items-center px-5 xl:flex-row mt-[5rem] lg:items-end lg:justify-center lg:gap-[6rem]">
      <motion.div
        transition={{ staggerChildren: 0.3 }}
        initial="initial"
        whileInView={"final"}
        viewport={{ once: true, amount: 0.1 }}
        className="mt-6 w-[80vw] xl:w-[411px] outline-black lg:w-[411px] "
      >
        <motion.h2
          variants={TextVarient}
          className="text-[48px] text-[#233348] font-bold leading-none mb-5"
        >
          Bikers For Le Bonheur 2023
        </motion.h2>
        <motion.p
          variants={AngelVarient}
          className="text-[21px] text-[#7D7987]"
        >
          2nd Annual LeBonheur Poker Run on August 26th
        </motion.p>
        <motion.form
          variants={AngelVarient}
          className="flex relative justify-between items-center p-5 mt-6"
        >
          <div className="flex justify-center items-center gap-1"></div>
          <a
            className=" flex absolute right-[5px] top-[1/2] justify-center items-center  px-11 py-2 rounded-[100px] lg:px-20 lg:py-2.5 text-white bg-gradient-to-br from-black to-black hover:bg-gradient-to-bl   font-medium rounded-lg text-md px-5 py-2.5 text-center mr-2 mb-2"
            href="/information"
          >
            Learn More
          </a>
        </motion.form>
      </motion.div>

      {/* Lottie */}
      <motion.div
        variants={BikeVarient}
        initial="initial"
        whileInView={"final"}
        viewport={{ once: true, amount: 0.1 }}
      >
        <Lottie
          className=" w-[380px]  lg:w-[400px]"
          animationData={headerLottie}
        />
      </motion.div>
    </div>
  );
}

export default Hero;
