import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import { Parallax, Pagination, Navigation } from "swiper";
import SwiperContent from "./SwiperContent";
import SignUp from "../assets/PNG/SignUp.png";
import Hospital from "../assets/JPEG/Hospital.jpg";
import { motion } from "framer-motion";
import RegisterButton from "./RegisterButton";
// import SignUp from "../page/signUp";

function Testimonies() {
  const sliderVarient = {
    initial: {
      x: 1000,
      opacity: 0,
    },
    final: {
      x: 0,
      opacity: 1,
    },
    transition: {
      ease: [0.17, 0.67, 0.83, 0.67],
    },
  };
  return (
    <>
      <motion.div
        variants={sliderVarient}
        initial="initial"
        animate={"final"}
        className="flex justify-center items-center w-[90vw] mt-[8rem] lg:w-[60vw] mx-auto "
      >
        <Swiper
          slidesPerView={1}
          navigation={{
            prevEl: ".swiper-custom-navigation-prev",
            nextEl: ".swiper-custom-navigation-next",
          }}
          className="mySwiper lg:h-[435px]"
        >
          <SwiperSlide>
            <SwiperContent
              img={SignUp}
              name="Register Today"
              job="Pro Rider"
              testimonies="Come join us for the 2nd Annual LeBonheur Poker Run, a benefit ride for LeBonheur Childerns Hospital. We are a small part of a larger corporation that come together to find ways to help raise money for all kids. "
              button={<RegisterButton />}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SwiperContent
              img={Hospital}
              name="Fundraising"
              job="Lead Engineer"
              testimonies="If you are interesting in fundraising for the 2nd Annual LeBonheur Poker Run, please see our fundraising page for more information and prices. Thank you for your contribution and happy riding."
              button={RegisterButton}
            />
          </SwiperSlide>
        </Swiper>
      </motion.div>
    </>
  );
}

export default Testimonies;
