// import Header from "./component/Header";
import Register from "./page/register";
import Fundraising from "./page/Fundraising.jsx";
import Contact from "./page/Contact";
import Home from "./page/Home";
import Forgot from "./page/Forgot";
import Login from "./page/Login";
import Information from "./page/Information";
import Dashboard from "./page/Dashboard";
// import Header from "./component/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./component/Header";
import { AuthProvider } from "./contexts/AuthContext";
import ProfileDashboard from "./component/Dashboard/ProfileDashboard";
import FundraisingDashboard from "./component/Dashboard/FundraisingDashboard";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div>
          <Header />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/fundraising" element={<Fundraising />} />
          <Route
            path="/fundraisingdashboard"
            element={<FundraisingDashboard />}
          />

          <Route path="/information" element={<Information />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/tickets" element={<Dashboard />} />
          <Route path="/profile" element={<ProfileDashboard />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
