import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";

const ForgotComponent = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email).then(() => {
        toast.success(
          "Password reset link has been sent. Please check your mailbox"
        );
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 5300);
      });
    } catch (err) {
      toast.error("An error occurred while sending mail.");
    }
  };
  return (
    <div class="flex flex-col overflow-auto justify-center items-center px-5 xl:flex-row mt-[3rem] lg:items-end lg:justify-center lg:gap-[6rem]0">
      <div class="flex flex-col items-center justify-center  p-4 space-y-4 antialiased text-gray-900 ">
        <div class="w-full px-8 max-w-lg space-y-5 bg-white rounded-md py-5">
          <h1 class=" mb-6 text-3xl font-bold text-center">Forgot Password</h1>
          <p class="text-center mx-12">
            We are here to help you to recover your password. Enter the email
            address you used when you joined and we'll send you instructions to
            reset your password.
          </p>
          <form action="#" class="space-y-6 w-ful">
            <input
              type="text"
              id="email"
              name="Email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-black text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
            <div>
              <button
                onClick={handlePasswordReset}
                type="button"
                class="w-full px-4 py-2 font-medium text-center text-white bg-black transition-colors duration-200 rounded-md bg-black hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1"
              >
                Send Email
              </button>
            </div>
          </form>
          <div class="text-sm text-gray-600 items-center flex justify-between">
            <a
              class="text-gray-800 cursor-pointer hover:text-red-600 inline-flex items-center ml-4"
              href="/login"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              Back
            </a>
            <p class="hover:text-blue-500 cursor-pointer"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotComponent;
