import React from "react";

import ForgotComponent from "../component/Register/ForgotComponent";
import Footer from "../component/Footer";

const Forgot = () => {
  return (
    <div>
      <ForgotComponent />
      <Footer />
    </div>
  );
};

export default Forgot;
