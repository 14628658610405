import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import headerLottie from "../assets/lottie/playing-cards.json";
import { Squash as Hamburger } from "hamburger-react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from "lottie-react";

function Header({ history }) {
  const handleLinkClick = () => {
    this.setState({ isOpen: false });
  };

  const logoVarient = {
    initial: {
      y: -200,
    },
    final: {
      y: 0,
    },
    transition: {
      ease: "easeInOut",
      delay: 1,
      duration: 2,
      staggerChildren: 0.5,
      staggerDelay: 0.7,
    },
  };
  const mobileVarient = {
    initial: {
      opacity: 0,
      scale: 0,
      height: "90vh",
      borderRadius: "100px",
      backgroundColor: "#000000",
      transition: {
        ease: [0.25, 0.1, 0.25, 1],
        duration: 1,
        delay: 0.2,
      },
    },
    final: {
      opacity: 1,
      scale: 1,
      backgroundColor: "#FE4531",
      borderRadius: 0,
      height: "100vh",
      transition: {
        ease: [0.25, 0.1, 0.25, 1],
      },
    },
    exit: {
      opacity: 0,
      scale: 0,
      transition: {
        ease: [0.25, 0.1, 0.25, 1],
        duration: 5,
        delay: 0.5,
      },
    },
  };
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <div className=" w-full flex justify-between items-center p-3 sticky shadow-md bg-white top-0 z-40 lg:shadow-none lg:static lg:justify-center lg:gap-[14rem] lg:pt-[2rem]">
        <a href="/">
          <motion.div
            variants={logoVarient}
            initial="initial"
            animate="final"
            className="flex justify-center items-center gap-3 overflow-hidden"
          >
            <Lottie
              className=" w-[80px]  lg:w-[80px]"
              animationData={headerLottie}
            />
            <motion.p
              animate={{ opacity: [0, 1], y: [-200, 0] }}
              transition={{ ease: "easeInOut", delay: 0.2 }}
              className="text-[#233348] text-[24px] font-bold"
            >
              Bikers For Le Bonheur
            </motion.p>
          </motion.div>
        </a>
        <motion.div animate={{ x: [2000, 0] }} className=" ">
          <nav>
            <ul className="hidden xl:flex justify-center items-center gap-5 cursor-pointer">
              <li className="text-[#7D7987] text-[18px]">
                <Link to="/">Home</Link>
              </li>
              <li className="text-[#7D7987] text-[18px]">
                <Link to="/register">Register</Link>
              </li>
              <li className="text-[#7D7987] text-[18px]">
                <Link to="/fundraising">Fundraising</Link>
              </li>
              <li className="text-[#7D7987] text-[18px]">
                <Link to="/information">Event Information</Link>
              </li>
              <li className="text-[#7D7987] text-[18px]">
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </nav>
        </motion.div>

        {/* Mobile Nav */}

        {isOpened && (
          <AnimatePresence>
            <motion.div key={1} className="z-20">
              <AnimatePresence>
                <motion.ul
                  key={2}
                  variants={mobileVarient}
                  initial="initial"
                  animate="final"
                  exit="exit"
                  className="flex text-[#fff] justify-center items-center flex-col gap-6 cursor-pointer w-screen h-screen absolute right-0 top-0 xl:hidden"
                >
                  <motion.li
                    animate={{ y: [-400, 0] }}
                    className="text-[#fff] text-[20px] font-bold overflow-hidden"
                  >
                    <Link
                      activeStyle={{ color: "#5754a8" }}
                      to="/"
                      onClick={handleLinkClick}
                    >
                      Home
                    </Link>
                  </motion.li>
                  <motion.li
                    animate={{ y: [-300, 0] }}
                    transition={{ delay: 0.1, type: "spring", stiffness: 400 }}
                    className="text-[#fff] text-[20px] xl:text-[#7D7987] overflow-hidden"
                  >
                    <Link to="/register" onClick={handleLinkClick}>
                      Register
                    </Link>
                  </motion.li>
                  <motion.li
                    animate={{ y: [-200, 0] }}
                    transition={{ delay: 0.4, type: "spring", stiffness: 400 }}
                    className="text-[#fff] text-[20px] xl:text-[#7D7987] overflow-hidden"
                  >
                    <Link to="/fundraising" onClick={handleLinkClick}>
                      Fundraising
                    </Link>
                  </motion.li>
                  <motion.li
                    animate={{ y: [-100, 0] }}
                    transition={{ delay: 0.6, type: "spring", stiffness: 100 }}
                    className="text-[#fff] text-[20px] xl:text-[#7D7987] overflow-hidden"
                  >
                    <Link to="/information" onClick={handleLinkClick}>
                      Event Information
                    </Link>
                  </motion.li>
                  <motion.li
                    animate={{ y: [-100, 0] }}
                    transition={{ delay: 0.6, type: "spring", stiffness: 100 }}
                    className="text-[#fff] text-[20px] xl:text-[#7D7987] overflow-hidden"
                  >
                    <Link to="/contact" onClick={handleLinkClick}>
                      Contact Us
                    </Link>
                  </motion.li>
                  <motion.li
                    animate={{ y: [-50, 0] }}
                    transition={{ delay: 0.8, type: "spring", stiffness: 400 }}
                    className=" text-[20px] text-[#fff] xl:text-[#7D7987] overflow-hidden"
                  ></motion.li>
                </motion.ul>
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>
        )}
        <div className="xl:hidden z-30">
          <Hamburger
            color="#000000"
            toggled={isOpened}
            toggle={setIsOpened}
            easing="ease-in"
            size={20}
            direction="left"
          />
        </div>
      </div>
    </>
  );
}

export default Header;
