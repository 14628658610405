import React from "react";
import About from "../../assets/JPEG/about.jpg";

const InformationComponent = () => {
  return (
    <div class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-10 lg:overflow-visible lg:px-0">
      <div class="absolute inset-0 -z-10 overflow-hidden">
        <svg
          class="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              stroke-width="0"
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            stroke-width="0"
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div class="lg:pr-4">
            <div class="lg:max-w-lg">
              <p class="text-base font-semibold leading-7 text-red-500">
                Event Information
              </p>
              <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Bikers For Le Bonheur 2023
              </h1>
              <p class="mt-6 text-xl leading-8 text-gray-700">
                It's that time of the year to Rev up your Bikes & your Hearts.
              </p>
            </div>
          </div>
        </div>
        <div class="-ml-0 -mt-0 p-5 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            class=" w-full max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem]"
            src={About}
            alt=""
          />
        </div>
        <div class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div class="lg:pr-4">
            <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Come join us for the 2nd Annual LeBonheur Poker Run, a benefit
                ride for LeBonheur Childerns Hospital. We are a small part of a
                larger corporation that come together to find ways to help raise
                money for all kids. Help us support the childern by getting out
                to ride. $25.00 per rider, and $10.00 per passenger.
              </p>

              <p class="mt-8">
                Registration is at 9AM - 10AM at Bumpus 2169 Whitten Road,
                Memphis TN.
                <br />
                <br />
                Kickstands up at 10AM.
                <br /> <br /> Come back to celebrate at Railgarten 2166 Central
                Ave, Memphis, TN 38104.
                <br />
                <br />
                Live Bands, Raffles, Prizes, and so much more.
              </p>
              <p class="mt-6">
                It's an event that is made friendly for everyone. Bring the kids
                and enjoy our kid-friendly zone!
                <br />
                <br />
                Look forward to seeing you there!
              </p>
              <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Fundraising
              </h2>
              <p class="mt-6">
                If you are interesting in fundraising for the 2nd Annual
                LeBonheur Poker Run, please see our{" "}
                <a class="underline" color="red-500" href="/fundraising">
                  fundraising page
                </a>{" "}
                for more information and prices. Thank you for your contribution
                and happy riding.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationComponent;
