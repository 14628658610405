import React from "react";

import LoginComponent from "../component/Register/LoginComponent";
import Footer from "../component/Footer";

const Login = () => {
  return (
    <div>
      <LoginComponent />
      <Footer />
    </div>
  );
};

export default Login;
