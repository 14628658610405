// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxxXpVmR_VoJSkJRMJrENlyIxaYh-Wp0E",
  authDomain: "bikersforlebonheur-dev.firebaseapp.com",
  databaseURL: "https://bikersforlebonheur-dev-default-rtdb.firebaseio.com",
  projectId: "bikersforlebonheur-dev",
  storageBucket: "bikersforlebonheur-dev.appspot.com",
  messagingSenderId: "669035520212",
  appId: "1:669035520212:web:59a80de331439e01da5b41",
  measurementId: "G-69W3GKRJ0L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {
  auth,
  db,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
};
